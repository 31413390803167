import React from "react";
import { graphql } from "gatsby";
import { Parallax } from "react-parallax";
import { RichText } from "prismic-reactjs";
import Layout from "../components/layouts";
import moment from "moment";

// Display the title, date, and content of the Post
const PostBody = ({ blogPost }) => {
  const { hero_image, title, published_date, content, _meta } = blogPost;
  const momentDate = moment(published_date).format("MMM Do YY");
  return (
    <div className="news-detail-inner" key={1}>
        <Parallax className="page-header" bgImage={hero_image.url} strength={400}>
            <div style={{height: '100vh', maxHeight: '550px'}}>
                <div className="page-header-overlay" />
                <h1 className="page-header-title white">{title ? title[0].text : ""}</h1>
            </div>
        </Parallax>
        <div className="news-info">
            
            <div className="news-info-date">
              Posted {momentDate}. 
              {/* {_meta.tags.length ? `Tags: ${_meta.tags.map((tag) => `#${tag} `)}` : null} */}
            </div>
            
            <a href="/news">Go back</a>
        </div>
        <div className="news-body">
          { RichText.render(content) }
        </div>
    </div>
  );
}

export default ({ data }) => {
  // Define the Post content returned from Prismic
  const doc = data.prismic.allNewss.edges.slice(0,1).pop();
  if(!doc) return null;

  return(
    <Layout>
      <div className="news-detail-page">
        <PostBody blogPost={ doc.node } />
      </div>
    </Layout>
  )
}

// Query for the Blog Post content in Prismic
export const query = graphql`
query BlogPostQuery($uid: String) {
  prismic{
    allNewss(uid: $uid){
      edges {
        node {
          content
          hero_image
          published_date
          title
          _meta {
            uid
            tags
          }
        }
      }
    }
  }
}
`
